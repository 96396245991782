export const OBLIGATION_GET_SUCCESS = 'OBLIGATION_GET_SUCCESS';
export const OBLIGATION_GET_REQUEST = 'OBLIGATION_GET_REQUEST';
export const OBLIGATION_GET_FAILURE = 'OBLIGATION_GET_FAILURE';

export const OBLIGATION_BILL_GET_SUCCESS = 'OBLIGATION_BILL_GET_SUCCESS';
export const OBLIGATION_BILL_GET_REQUEST = 'OBLIGATION_BILL_GET_REQUEST';
export const OBLIGATION_BILL_GET_FAILURE = 'OBLIGATION_BILL_GET_FAILURE';

export const OBLIGATION_FIND_SUCCESS = 'OBLIGATION_FIND_SUCCESS';
export const OBLIGATION_FIND_REQUEST = 'OBLIGATION_FIND_REQUEST';
export const OBLIGATION_FIND_FAILURE = 'OBLIGATION_FIND_FAILURE';

export const OBLIGATION_ACTION_SUCCESS = 'OBLIGATION_ACTION_SUCCESS';
export const OBLIGATION_ACTION_REQUEST = 'OBLIGATION_ACTION_REQUEST';
export const OBLIGATION_ACTION_FAILURE = 'OBLIGATION_ACTION_FAILURE';

export const OBLIGATION_SUBMIT_SUCCESS = 'OBLIGATION_SUBMIT_SUCCESS';
export const OBLIGATION_SUBMIT_REQUEST = 'OBLIGATION_SUBMIT_REQUEST';
export const OBLIGATION_SUBMIT_FAILURE = 'OBLIGATION_SUBMIT_FAILURE';

export const OBLIGATION_DELETE_SUCCESS = 'OBLIGATION_DELETE_SUCCESS';
export const OBLIGATION_DELETE_REQUEST = 'OBLIGATION_DELETE_REQUEST';
export const OBLIGATION_DELETE_FAILURE = 'OBLIGATION_DELETE_FAILURE';

export const OBLIGATION_FORM_SUCCESS = 'OBLIGATION_FORM_SUCCESS';
export const OBLIGATION_FORM_REQUEST = 'OBLIGATION_FORM_REQUEST';
export const OBLIGATION_FORM_FAILURE = 'OBLIGATION_FORM_FAILURE';

